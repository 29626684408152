import alt from '../alt';
import PositionActions from '../actions/PositionActions';

class PositionStore {
  constructor() {
    this.bindListeners({
      getCurrentPosition: PositionActions.getCurrentPosition,
      positionDetectionOver: PositionActions.positionDetectionOver
    })
    this.state = {
      detectingLocation: false
    }
  }

  getCurrentPosition() {
      this.setState({ detectingLocation: true });
  }

  positionDetectionOver() {
      this.setState({ detectingLocation: false });
  }

}

export default alt.createStore(PositionStore, 'PositionStore');
