import React from 'react';
import PropTypes from 'prop-types';
import PositionActions from '../actions/PositionActions.js';
require('styles/locationButton.css');

import locateIcon from '../images/locate-icon.png';
import deactivatedLocateIcon from '../images/deactivated-locate-icon.png';

export default class LocationButton extends React.Component {

  render() {
    return (
      this.props.detectingLocation ?
        (
          <a id="currentLocationButton" className="floating-button" >
            <img src={deactivatedLocateIcon} alt="Deactivated current location" tabIndex="0" />
          </a>
        ) :
        (
          <a id="currentLocationButton" className="floating-button" onClick={PositionActions.getCurrentPosition}
            onKeyUp={(event) => event.key === 'Enter' ? PositionActions.getCurrentPosition() : undefined}>
            <img src={locateIcon} alt="Current location" tabIndex="0" />
          </a>
        )
    )
  }
}

LocationButton.propTypes = {
  gotoCurrentLocation: PropTypes.func,
  detectingLocation: PropTypes.bool
};

LocationButton.defaultProps = {
};
