require('../../node_modules/leaflet/dist/leaflet.css');
require('../styles/map.css');

import Address from './Address';
import React from 'react';
import PropTypes from 'prop-types';
import { Map, TileLayer, Marker, Popup, ZoomControl, AttributionControl } from 'react-leaflet';
import L from 'leaflet';
import config from 'config';
import MarkerCluster from './MarkerCluster.js';
import PollingStationInfo from './PollingStationInfo';
import { focusInfoPopup, titleStationIcons, notifyAboutClosestStation } from '../utils/accessibility';
import TranslateStore from '../stores/TranslateStore';
import MapActions from '../actions/MapActions';
class MapContainer extends React.Component {

  constructor() {
    super();
    this.crs = L.CRS.EPSG3857;
    var markerIconHtml = '<i class="fa fa-map-marker fa-3x"></i>';
    this.icon = L.divIcon({ className: 'map-marker-icon', html: markerIconHtml, iconSize: [21, 36], iconAnchor: [10, 33], popupAnchor: [0, -33] });
    var stationIconHtml = '<i class="fa fa-bank fa-2x station-icon"></i>';
    this.stationIcon = L.divIcon({ className: 'polling-stations-icon', html: stationIconHtml, iconSize: [27, 24], popupAnchor: [0, -10] });
    this.pixelRatio = window.devicePixelRatio || 1;

    this.markerClusterRef = React.createRef();
  }

  openClosestStation() {
    const cluster = this.markerClusterRef.current;
    if (cluster) {
      // get layer closest to the searched address
      const closestLayer = cluster.geoJSONLayer.getLayers().filter(layer => layer.feature.properties.id === this.props.map.closestAdvancePollingStationInfo.id)[0];
      if (!closestLayer)
        return;

      // Make closest stations focusable by keyboard and title it for screen reader
      titleStationIcons();

      // Open the popup and focus it
      closestLayer.openPopup();
      focusInfoPopup(closestLayer.feature.properties.id);

      // Notify screen reader users about the closest station
      const stationname = TranslateStore.getLocalisedValue('stationname', closestLayer.feature.properties);
      notifyAboutClosestStation(stationname);
    }
  }

  componentDidUpdate() {
    this.preventClusterTabbing()

    if (!this.props.map.closestAdvancePollingStationInfo)
      return;

    // If the closest advance polling station has changed, open the new station popup
    if (this.props.map.closestStationHasChanged) {
      setTimeout(this.openClosestStation.bind(this), 200);
      MapActions.stopSettingClosestAdvancePollingStation();
    }
  }

  // Prevent focusing of clusters by keyboard, impoves accessibility
  preventClusterTabbing() {
    const clusters = document.getElementsByClassName('marker-cluster');

    for (let cluster of clusters) {
      cluster.tabIndex = '-1';
    }
  }

  render() {
    return (
      <Map onZoomEnd={() => this.preventClusterTabbing()} minZoom={3} worldCopyJump={true} center={this.props.map.center} {...(this.props.map.boundingBox && { bounds: this.props.map.boundingBox })}
        boundsOptions={{ paddingTopLeft: [55, 200], paddingBottomRight: [20, 20] }} crs={this.crs}
        zoom={this.props.map.zoom} style={{ 'height': '100%', 'width': '100%' }} zoomControl={false} attributionControl={false}>
        <AttributionControl role="presentation" prefix="<a href='https://www.cgi.fi' target='blank' rel='noopener noreferrer'>&copy; CGI</a> | <a href='http://leafletjs.com/' target='blank' rel='noopener noreferrer'>Leaflet</a>" />
        <TileLayer
          url="https://maps.hereapi.com/v3/{base}/mc/{z}/{x}/{y}/{format}?apiKey=OP6HTQmp5BPMtJJQgtgeC7kqwtQlmJ5_Rbm10_p7Jpo&style={scheme}&ppi={ppi}&size={size}&lang=fi"
          attribution="&copy; <a href='http://developer.here.com' target='blank' rel='noopener noreferrer'>HERE</a>"
          subdomains="1234"
          base="base"
          env={config.appEnv === 'dist' ? 'api' : 'cit.api'}
          resource="maptile"
          mapId="newest"
          scheme="explore.day"
          size="256"
          format="png8"
          apiKey="OP6HTQmp5BPMtJJQgtgeC7kqwtQlmJ5_Rbm10_p7Jpo"
          detectRetina={true}
          ppi="400"
        />

        {
          this.props.map.pageContext != 'electionDayVoting' &&
          <MarkerCluster ref={this.markerClusterRef}
            data={this.props.pollingStations.advancedPollingStations}
          />
        }
        {
          this.props.map.currentLocationCoords &&
          <Marker position={this.props.map.currentLocationCoords} icon={this.icon} alt="Your location" title="Your location">
            <Popup autoPanPaddingTopLeft={[10, 150]}>
              {this.props.map.currentLocationInfo ? <Address data={this.props.map.currentLocationInfo} /> : <span>Olet tässä!</span>}
            </Popup>
          </Marker>
        }
        {
          this.props.map.pollingStationCoords && this.props.map.pollingStationInfo &&
          <Marker ref={(r) => {
            if (r && r.leafletElement) r.leafletElement.openPopup();
          }}
            position={this.props.map.pollingStationCoords} icon={this.stationIcon} alt="Polling station" >
            <Popup autoPanPaddingTopLeft={[10, 100]} >
              <PollingStationInfo info={this.props.map.pollingStationInfo} isClosestStation={true} />
            </Popup>
          </Marker>
        }
        {/* zoomcontrol is not shown when window's width is less than 505px. Done with css in styles/map.css*/}
        <ZoomControl position="bottomright" />
      </Map>
    );
  }
}

MapContainer.propTypes = {
  map: PropTypes.shape({
    center: PropTypes.array,
    boundingBox: PropTypes.array,
    pageContext: PropTypes.oneOf(['electionDayVoting', 'advanceVoting']),
    currentLocationCoords: PropTypes.array,
    currentLocationInfo: PropTypes.object,
    pollingStationCoords: PropTypes.array,
    pollingStationInfo: PropTypes.object,
    closestAdvancePollingStationInfo: PropTypes.object,
    closestStationHasChanged: PropTypes.bool,
    zoom: PropTypes.number
  }),
  pollingStations: PropTypes.shape({
    advancedPollingStations: PropTypes.shape({
      crs: PropTypes.object,
      features: PropTypes.array
    })
  })
}

export default MapContainer;
