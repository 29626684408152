require('styles/App.css');

import React from 'react';
import MapContainer from './MapContainer';
import Search from './Search';
import Info from './Info';
import LocationButton from './LocationButton';
import APollingStationsActions from '../actions/APollingStationsActions';
import APollingStationsStore from '../stores/APollingStationsStore';
import AppOptionsActions from '../actions/AppOptionsActions'
import AppOptionsStore from '../stores/AppOptionsStore';
import SearchStore from '../stores/SearchStore';
import MapStore from '../stores/MapStore';
import PositionStore from '../stores/PositionStore';
import PositionActions from '../actions/PositionActions';
import AltContainer from 'alt-container';

import { isServiceOpened, isServiceClosed } from '../stores/dates'

class AppComponent extends React.Component {

  componentDidMount() {
    setTimeout(() => {
      AppOptionsActions.fetchAppOptions();
    }); 
    if (MapStore.getState().pageContext == 'advanceVoting') {
      setTimeout(() => {
        APollingStationsActions.fetchStations();
        PositionActions.getCurrentPosition();
      });
    }
  }

  renderApp() {
    return (
      <div className="index">
        <AltContainer stores={{ search: SearchStore, map: MapStore }}>
          <Search />
        </AltContainer>
        <AltContainer stores={{ map: MapStore, pollingStations: APollingStationsStore, appOptions: AppOptionsStore }}>
          <MapContainer />
        </AltContainer>
        <AltContainer store={PositionStore}><LocationButton /></AltContainer>
        <AltContainer store={MapStore}>
          <Info />
        </AltContainer>
        <div id="announcement" aria-live="assertive" className="screen-reader-text sr-only"></div>
      </div>
    );
  }

  renderServiceWillOpen() {
    return (
      <div className='info-page'>
        <h1>Alue- ja kuntavaalit 2025</h1>
        <p>Äänestyspaikkapalvelu julkaistaan maaliskuun aikana.</p>
      </div>
    );
  }

  renderVotingClosed() {
    return (
      <div className='info-page'>
        <h1>Alue- ja kuntavaalit 2025</h1>
        <p>Äänestys on päättynyt.</p>
      </div>
    );
  }

  render() {
    if (!isServiceOpened()) {
      return this.renderServiceWillOpen();
    }

    if (isServiceClosed()) {
      return this.renderVotingClosed();
    }

    return this.renderApp();
  }
}

AppComponent.defaultProps = {
};

export default AppComponent;
