import alt from '../alt';
import AppOptionsActions from '../actions/AppOptionsActions';

class AppOptionsStore {
  constructor() {
    this.bindListeners({
        fetchAppOptions: AppOptionsActions.fetchAppOptions,
        updateAppOptions: AppOptionsActions.updateAppOptions
    })
    this.state = {
      appOptions: null
    }
  }

  fetchAppOptions() {
    this.setState({
      appOptions: null
    })
  }

  updateAppOptions(data) {
    this.setState({
      appOptions: data
    })
  }
}

export default alt.createStore(AppOptionsStore, 'AppOptionsStore');
