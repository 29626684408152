import alt from '../alt';
import Proj4 from 'proj4';
import APollingStationsActions from './APollingStationsActions';
import { srSpeak } from '../utils/accessibility';
import TranslateStore from '../stores/TranslateStore';

class MapActions {

    onAddressChosen(item, shouldFetchPollingStation) {
        return (dispatch) => {
            dispatch();
            var coords = this.project(item.geometry.coordinates);
            this.showMarker(coords, item.properties);
            if (shouldFetchPollingStation) {
                this.fetchPollingStation(item.properties, coords)
            } else {
                this.fetchAdvancePollingStation(item.geometry.coordinates)
            }
        }
    }

    onMunicipalityOrCountryChosen(item) {
        return (dispatch) => {
            dispatch();
            let rawBounds = item.geometry.coordinates[0];
            let EPSG4326Bounds  = []
            rawBounds.forEach((coords) => {
                EPSG4326Bounds.push(this.project(coords));
            });
            this.fitMapToBoundingBox(EPSG4326Bounds);
        }
    }

    changeContext( newContext ) {
        return (dispatch) => {
            dispatch(newContext);
            if(newContext == 'advanceVoting'){
                /*
                    we are making things asychronous with setTimeout
                    so that tab change happens right away in UI
                */
                setTimeout(APollingStationsActions.fetchStations())
                this.hidePollingStation();
                srSpeak(TranslateStore.translate('search.choose') + TranslateStore.translate('advance_voting'));
            } else {
                srSpeak(TranslateStore.translate('search.choose') + TranslateStore.translate('election_day_voting'));
            }
        }
    }

    removeBoundingBox() { return null }

    showMarker(markerCoords, info) {
        return {
            currentLocationCoords: markerCoords,
            currentLocationInfo: info
        }
    }

    fitMapToBoundingBox(bounds) {
        return bounds;
    }

    showPollingStation(pollingStationCoords, pollingStationProps, bounds) {
        return {pollingStationCoords: pollingStationCoords, pollingStationProps: pollingStationProps, bounds: bounds}
    }

    setClosestAdvancePollingStation(pollingStationProps) {
        return {pollingStationProps: pollingStationProps}
    }

    stopSettingClosestAdvancePollingStation() {
        return;
    }

    // Election day polling station that is within provided municipality and polling district
    /**
    * @param {object} props - props with at least pollingmunicipalitycode & pollingdistrictcode properties
    * @param {Array} addressCoords - address's coordinates that is used to fit map to bound created by it and polling station's coordinates
    **/
    fetchPollingStation(props, addressCoords) {
        //getstation?pollingmunicipalitycode=91&pollingdistrictcode=046A
        return (dispatch) => {
            dispatch()
            fetch('/api/getstation/?pollingmunicipalitycode='+props.pollingmunicipalitycode+'&pollingdistrictcode='+props.pollingdistrictcode).then(dataPromise => {
                dataPromise.json().then( result => {
                    if(result.features && result.features[0]){
                        var pollingStationCoords = this.project(result.features[0].geometry.coordinates);
                        this.showPollingStation(pollingStationCoords, result.features[0].properties, [pollingStationCoords,addressCoords]);
                    }
                })
            }).catch(e => {
                console.log('Fetching polling station for address failed');
            })
        }
    }

    // Advance polling station that is nearest to coordinates provided
    fetchAdvancePollingStation(coords) {
        //getstation?pollingmunicipalitycode=91&pollingdistrictcode=046A
        return (dispatch) => {
            dispatch()
            fetch('/api/getadvancestation/?x='+coords[0]+'&y='+coords[1]).then(dataPromise => {
                dataPromise.json().then( result => {
                    if(result.features && result.features[0]){
                        let pollingStationCoords = this.project(result.features[0].geometry.coordinates);
                        var bounds = [pollingStationCoords, this.project(coords)];
                        this.removeBoundingBox();
                        this.setClosestAdvancePollingStation(result.features[0].properties);
                        setTimeout(this.fitMapToBoundingBox(bounds))
                    }
                })
            }).catch(e => {
                console.log('Fetching advance polling station closest to the coordinates failed');
            })
        }
    }


    reverseGeocode(coords, fetchPollingStation) {
        return () => {
            var coordinates = this.unproject(coords);
            fetch('/api/reversegeocoding/?x='+coordinates[0]+'&y='+coordinates[1]).then(dataPromise => {
                dataPromise.json().then( result => {
                    let featuresByType = this.mapByType(result.features)
                    if (featuresByType.address) {
                        let addressLocationCoords = this.project(featuresByType.address.geometry.coordinates);
                        let addressProps = featuresByType.address.properties;
                        this.showMarker(addressLocationCoords, addressProps);
                        if (fetchPollingStation) {
                            //Electionday voting
                            this.fetchPollingStation( addressProps, addressLocationCoords);
                        } else {
                            //Advanced voting
                            this.fetchAdvancePollingStation(featuresByType.address.geometry.coordinates)
                        }
                    } else {
                        let item = featuresByType.country
                        let rawBounds = item.geometry.coordinates[0];
                        let EPSG4326Bounds  = []
                        rawBounds.forEach((coords) => {
                            EPSG4326Bounds.push(this.project(coords));
                        });
                        EPSG4326Bounds.push(coords);
                        this.fitMapToBoundingBox(EPSG4326Bounds);
                    }
                })
            }).catch(e => {
                console.log('Reverse geocoding failed');
            })
        }
    }

    hidePollingStation() {
        return null
    }

    mapByType(features) {
        var featuresArransged = {};

        features.forEach((f) => {
            featuresArransged[f.properties.resulttype] = f
        })
        return featuresArransged;
    }

    project( coordinates ) {
        // To EPSG:4326 from EPSG:3857
        var coords = Proj4('EPSG:3857','EPSG:4326', coordinates)
        return [coords[1], coords[0]]
    }

    unproject( coordinates ) {
        // To EPSG:3857 from EPSG:4326
        return Proj4('EPSG:4326','EPSG:3857',[coordinates[1], coordinates[0]])
    }
}

export default alt.createActions(MapActions);
