import alt from '../alt';

class SearchActions {
    makeSearchRequest( query ) {
        return (dispatch) => {
            dispatch();
            fetch('/api/geocoding/?text='+encodeURIComponent(query)).then(dataPromise => {
                dataPromise.json().then( searchResults => {
                    if(!searchResults.features) searchResults.features = []
                    if(searchResults.features.length > 10) searchResults.features = searchResults.features.splice(0, 9)
                    this.showSearchResults(searchResults)
                })
            }).catch(e => {
                console.log('Search request failed!');
                this.errorFetchingSearResults();
            })
        }
    }

    showSearchResults( results ) {
        return results;
    }

    hideSearchResultDropdown() {
        return null;
    }

    errorFetchingSearResults() {
        return null;
    }
}

export default alt.createActions(SearchActions);
