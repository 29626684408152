import alt from '../alt';
import MapActions from '../actions/MapActions';
import MapStore from '../stores/MapStore';
import TranslateStore from '../stores/TranslateStore';

class PositionActions {

    getCurrentPosition() {
        return (dispatch) => {
            dispatch();
            if (navigator.geolocation) {
                this.clearTimeout();
                this.detectPositionTimeout = setTimeout(()=>{
                    this.clearTimeout();
                    this.positionDetectionOver();
                }, 60010)
                navigator.geolocation.getCurrentPosition((position) => {
                        this.positionRecieved(position);
                    }, (error) => {
                        this.handlePositionError(error);
                    },
                    { enableHighAccuracy: true, timeout: 60000, maximumAge: 60000 }
                );
            } else {
                this.browserNotSupported();
            }
        }
    }

    handlePositionError(error) {
        switch(error.code) {
            case error.PERMISSION_DENIED:
                this.permissionDenied();
                break;
            case error.POSITION_UNAVAILABLE:
                this.browserNotSupported();
                break;
            default:
                this.timeIsOut();
                break;
            }
        return error;
    }

    positionRecieved( position ) {
        this.positionDetectionOver();
        let currentPosition = [position.coords.latitude, position.coords.longitude];
        MapActions.showMarker(currentPosition);
        MapActions.reverseGeocode(currentPosition, MapStore.getState().pageContext == 'electionDayVoting');
        return position;
    }

    browserNotSupported() {
        this.positionDetectionOver();
        alert(TranslateStore.translate('geolocation_not_supported_message'));
        return null;
    }

    permissionDenied() {
        this.positionDetectionOver();
        alert(TranslateStore.translate('geolocation_denied_message'));
        return null;
    }

    clearTimeout() {
        if (this.detectPositionTimeout) {
            clearTimeout(this.detectPositionTimeout);
            this.detectPositionTimeout = null;
        }
        return null;
    }

    timeIsOut() {
        this.clearTimeout();
        this.positionDetectionOver();
        alert(TranslateStore.translate('geolocation_watch_timeout_message'));
        return null;
    }

    positionDetectionOver() {
        return null;
    }
}

export default alt.createActions(PositionActions);
