import TranslateStore from '../stores/TranslateStore';

const srSpeak = (text) => {
  var el = document.getElementById('announcement');
  el.innerHTML = text;
}

const focusInfoPopup = (id) => {
  const infoDiv = document.getElementById(id);
  if (!infoDiv || !infoDiv.parentElement || !infoDiv.parentElement.parentElement)
    return;

  const infoPopupWrapper = infoDiv.parentElement.parentElement;

  infoPopupWrapper.tabIndex = '0';
  infoPopupWrapper.focus();
}

const titleStationIcons = () => {
  const stations = document.getElementsByClassName('polling-stations-icon');
  for (let station of stations) {
    station.tabIndex = '0';
    station.firstChild.title = TranslateStore.translate('station_info.polling_station'); // Screen reader
  }
}

const notifyAboutClosestStation = (stationname) => {
  srSpeak(TranslateStore.translate('station_info.station_focus') + stationname + '. ' + TranslateStore.translate('station_info.station_focus_2'));
}

export { srSpeak, focusInfoPopup, titleStationIcons, notifyAboutClosestStation };