import alt from '../alt';
class APollingStationsActions {

    fetchStations() {
        return (dispatch) => {
            dispatch();
            fetch('/api/getFeatures', {headers: {'cache-control': 'max-age=86400'}}).then(dataPromise => {
                dataPromise.json().then( data => {
                    this.updateStations(data);
                })
            }).catch(() => {
                console.log('Fetching all advanced polling stations failed');
            })
        }
    }

    updateStations(stations) {
      return stations;
    }
}

export default alt.createActions(APollingStationsActions);
