import alt from '../alt';
import TranslateActions from '../actions/TranslateActions';
import Fi from '../locales/fi';
import Sv from '../locales/sv';
import En from '../locales/en';

class TranslateStore {
  constructor() {

    this.availableLocales = ['fi','sv','en'];
    this.l = {
        fi : Fi,
        sv: Sv,
        en: En
    }

    this.bindListeners({
        changeLanguage: TranslateActions.changeLanguage
    })

    this.exportPublicMethods({
        translate: this.translate.bind(this),
        getLocalisedValue: this.getLocalisedValue.bind(this),
        getLocale: this.getLocale.bind(this)
    });

    this.state = {
        locale: this.availableLocales[0]
    }
  }

  getLocale() {
    return this.state.locale;
  }

  changeLanguage(newLocale) {
      this.setState({locale: newLocale})
  }

  translate(key) {
      let keyTree = key.split('.');
      var langBook = this.l[this.state.locale]
      while (keyTree.length > 0) {
          var curNode = keyTree[0];
          langBook = langBook[curNode];
          keyTree.splice(0,1);
      }
      return langBook;
  }

  // if have object such as {namefi: 'Markku', nameen: 'Mark'} and
  //you want to access right value depending on current locale preference then this is the solution
  getLocalisedValue(keyBase, properties) {
      let val;

      if (Object.prototype.hasOwnProperty.call(properties, keyBase+this.state.locale) && properties[keyBase+this.state.locale]){
          val = properties[keyBase+this.state.locale]
      } else if (Object.prototype.hasOwnProperty.call(properties, keyBase+this.availableLocales[0]) && properties[keyBase+this.availableLocales[0]]) {
          val = properties[keyBase+this.availableLocales[0]]
      } else if (Object.prototype.hasOwnProperty.call(properties, keyBase+this.availableLocales[1]) && properties[keyBase+this.availableLocales[1]]) {
          val = properties[keyBase+this.availableLocales[1]]
      } else if (Object.prototype.hasOwnProperty.call(properties, keyBase+this.availableLocales[2]) && properties[keyBase+this.availableLocales[2]]) {
          val = properties[keyBase+this.availableLocales[2]]
      }
      return val
  }

}

export default alt.createStore(TranslateStore, 'TranslateStore');
