export default {
  election_day_voting: 'Röstning på valdagen',
  advance_voting: 'Förhandsröstning',
  info: {
    title: 'Välfärdsområdes- och kommunalvalet 2025: Röstningsställena',
    election_day_voting_title: 'Röstningsställen på valdagen den 13 april 2025',
    advance_voting_title: 'Förhandsröstningsställen',
    description: 'Information om förhandsröstningen och valdagsröstningen samt instruktioner för användning av applikationen',
    election_day_voting_info: {
      1: `
          <p>
              På valdagen kan du endast rösta på ditt eget röstningsställe som har fastställts utifrån din stadigvarande adress <span aria-hidden="true"><b>21 februari 2025</b></span><span class="sr-only">tjugoförsta februari 2025</span>. 
              Var och ens röstningsställe på valdagen står i anmälan om rösträtt som skickats till alla röstberättigade.
          </p>

          <p>
              Du kan kontrollera ditt röstningsställe på kartan genom att skriva in den stadigvarande adress som du hade den <span aria-hidden="true"><b>21 februari 2025</b></span><span class="sr-only">tjugoförsta februari 2025</span> i sökfältet. 
              Välj sedan rätt adress bland de förslag som öppnas i fältet. 
          </p>
        `,
      2: `
          <p>
              På valdagen kan du endast rösta på ditt eget röstningsställe som har fastställts utifrån din stadigvarande adress <span aria-hidden="true"><b>21 februari 2025</b></span><span class="sr-only">tjugoförsta februari 2025</span>. 
              Var och ens röstningsställe på valdagen står i anmälan om rösträtt som skickats till alla röstberättigade.
          </p>

          <p>
              Du kan kontrollera ditt röstningsställe på kartan genom att skriva in den stadigvarande adress som du hade den <span aria-hidden="true"><b>21 februari 2025</b></span><span class="sr-only">tjugoförsta februari 2025</span> i sökfältet. 
              Välj sedan rätt adress bland de förslag som öppnas i fältet. 
          </p>
        `
    },
    advance_voting_info: {
      1: `
          <p>
              Förhandsröstningen är på gång i Finland den <span aria-hidden="true"><b>den 2-8 april 2025</b></span><span class="sr-only">andra april till åttonde april 2025</span> och utomlands den <span aria-hidden="true"><b>den 2-5 april 2025</b></span><span class="sr-only">andra april till femte april 2025</span>. 
              Du kan förhandsrösta vid vilket allmänt förhandsröstningsställe som helst.
          </p>
          <p>
              Du kan söka upp ett lämpligt förhandsröstningsställe för dig på kartan genom att:
              <ul>
                  <li>förstora, förminska och flytta på kartan</li>
                  <li>ange gatuadress eller kommun i sökfältet </li>
                  <li>ange stat i sökfältet (förhandsröstningsställen utomlands)</li>
                  <li>använda lokaliseringsfunktionen </li>
              </ul>
          </p>
        `,
      2: `
          <p>
              Förhandsröstningen är på gång i Finland den <span aria-hidden="true"><b>den 2-8 april 2025</b></span><span class="sr-only">andra april till åttonde april 2025</span> och utomlands den <span aria-hidden="true"><b>den 2-5 april 2025</b></span><span class="sr-only">andra april till femte april 2025</span>. 
              Du kan förhandsrösta vid vilket allmänt förhandsröstningsställe som helst.
          </p>
          <p>
              Du kan söka upp ett lämpligt förhandsröstningsställe för dig på kartan genom att:
              <ul>
                  <li>förstora, förminska och flytta på kartan</li>
                  <li>ange gatuadress eller kommun i sökfältet </li>
                  <li>ange stat i sökfältet (förhandsröstningsställen utomlands)</li>
                  <li>använda lokaliseringsfunktionen </li>
              </ul>
          </p>
        `
    },
    read_more: 'Mer information om valet: <a href=https://www.valfinland.fi>www.valfinland.fi</a>'
  },
  footer: {
    attributes: `
            <span>© Myndigheten för digitalisering och befolkningsdata, CGI 2025</span> <br /> <span>
            Återkoppling: aanestyspaikat@dvv.fi</span> <br /> <span>
            <a href='accessibility_statement_sv.html' target='_blank'>Tillgänglighetsförklaring</a><br />
            <a href='https://dvv.fi/sv/dataskyddsbeskrivning-for-soktjansten-for-rostningsstallen' target='_blank'>Sekretesspolicy</a><br />
            Powered by <a href='https://www.cgi.fi' target='_blank'>CGI</a>
            </span>
        `
  },
  search: {
    no_result: 'Ingen hittade!',
    placeholder: 'Fågelbergavägen 19, Esbo',
    address_input: 'Adressinmatning',
    address_input_instructions: `Skriv adressen du vill veta närmaste vallokal till. Applikationen kommer att föreslå adresser när du har skrivit minst fyra tecken.
      När resultat för autoslutförande är tillgängliga, navigera i sökresultaten med upp- och nedpilarna. Välj sökresultat genom att trycka på enter.`,
    result_report: ' sökresultat att välja mellan. Navigera i sökresultaten med upp- och nedpilarna. Välj sökresultat genom att trycka på enter.',
    no_result_report: 'Inga resultat är tillgängliga. Prova att ändra sökningen.',
    choose: 'Du valde ',
    context: 'Du har valt '
  },
  station_info: {
    station_focus: 'Närmaste vallokal är ',
    station_focus_2: 'Information om närmsta vallokalen kommer att läsas upp. Du kan också själv navigera i informationen med piltangenterna.',
    open_on: 'Vallokalen är öppen ',
    date: 'Datum ',
    time: 'Tid ',
    polling_station: 'Vallokal '
  },
  geolocation_denied_message: 'Du har inte aktiverat platstjänster. Du kan aktivera platsdelning via webbläsarens eller telefonens inställningar.',
  geolocation_not_supported_message: 'Platstjänster otillgängliga.',
  geolocation_prompt_message: 'Vänligen tillåt delning av platsinformation.',
  geolocation_watch_timeout_message: 'Det tar längre tid än väntat att hitta din plats. Har du godkänt att webbläsaren får använda din plats?'
}
