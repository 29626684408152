import alt from '../alt';
class AppOptionsActions {

    fetchAppOptions() {
        return (dispatch) => {
            dispatch();
            fetch('/api/options', {headers: {'cache-control': 'max-age=86400'}}).then(dataPromise => {
                dataPromise.json().then( data => {
                    this.updateAppOptions(data)
                })
            }).catch(() => {
                console.log('Fetching app options failed');
            })
        }
    }

    updateAppOptions(options) {
        return options
    }
}

export default alt.createActions(AppOptionsActions);
