import alt from '../alt';
import SearchActions from '../actions/SearchActions';

class SearchStore {
  constructor() {
    this.bindListeners({
      makeSearchRequest: SearchActions.makeSearchRequest,
      showSearchResults: SearchActions.showSearchResults,
      hideSearchResultDropdown: SearchActions.hideSearchResultDropdown,
      errorFetchingSearResults: SearchActions.errorFetchingSearResults
    })
    this.state = {
      searchResults: null,
      openResultDropDown: false,
      fetchingSearchResults: false
    }
  }

  makeSearchRequest() {
      this.setState({ fetchingSearchResults: true })
  }

  showSearchResults(results) {
      this.setState({ searchResults: results, openResultDropDown: true, fetchingSearchResults: false })
  }

  errorFetchingSearResults() {
      this.setState({ searchResults: null, openResultDropDown: false, fetchingSearchResults: false })
  }

  hideSearchResultDropdown() {
      this.setState({ openResultDropDown: false })
  }
}

export default alt.createStore(SearchStore, 'SearchStore');
