import 'styles/info.css';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InfoPanel from './InfoPanel';
import OnClickOutside from 'react-onclickoutside';
const OutsideClickableInfoPanel = OnClickOutside(InfoPanel);

const Info = () => {
    const [infoOpen, setInfoOpen] = useState(true)

    return (
        <div id="info-container">
            {infoOpen && <div style={{pointerEvents: 'none'}} className="middleground" ></div> }
            <OutsideClickableInfoPanel infoClassName={infoOpen ? 'infoshown' : ''} infoOpen={infoOpen} setInfoOpen={setInfoOpen} />
        </div>
    );
}

Info.propTypes = {
    infoOpen: PropTypes.bool
};

export default Info;
