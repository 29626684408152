import alt from '../alt';
class TranslateActions {

    changeLanguage(newLang) {
        document.documentElement.setAttribute('lang', newLang); // Change html lang attribute
        return newLang;
    }
}

export default alt.createActions(TranslateActions);
