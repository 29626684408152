import 'styles/search.css';

import React from 'react';
import PropTypes from 'prop-types';
import SearchResults from './SearchResults';
import OnClickOutside from 'react-onclickoutside';
import SearchActions from '../actions/SearchActions';
import MapActions from '../actions/MapActions';
import TranslateStore from '../stores/TranslateStore';
import T from './Translate';
import { isAdvancePollingEnded } from '../stores/dates';
import vrkLogo from '../images/vrk-logo.png';

const OutsideClickableSearchResults = OnClickOutside(SearchResults);

class Search extends React.Component {

  constructor() {
    super();
    this.state = { query: '', searchPlaceHolder: TranslateStore.translate('search.placeholder') }
    this.queryTimer = null;
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.searchInput.focus();

    // Did not find nice way to translate placeholder of searchInput
    // So, this component listens to TranslateStore state change and
    // updates the state of this component with new searchPlaceHolder text
    TranslateStore.listen(this.onChange);
  }

  componentWillUnmount() {
    TranslateStore.unlisten(this.onChange);
  }

  blurSearch() {
    this.searchInput.blur();
  }

  onChange() {
    this.setState({ searchPlaceHolder: TranslateStore.translate('search.placeholder') })
  }

  searchInputOnChange() {
    this.setState({
      query: this.searchInput.value
    })
    if (this.searchInput.value.split('').length > 3) {
      if (this.queryTimer) { clearTimeout(this.queryTimer); this.queryTimer = null }
      this.queryTimer = setTimeout(() => { SearchActions.makeSearchRequest(this.searchInput.value.replace(/\s+/g, ' ').trim()) }, 500)
    } else {
      this.hideSearchResultsDropDown();
    }
  }

  onSearchResultClicked(clickedItem) {
    if (this.searchInput == document.activeElement) return;
    this.hideSearchResultsDropDown();
    switch (clickedItem.properties.resulttype) {
      case 'address':
        MapActions.onAddressChosen(clickedItem, this.props.map.pageContext == 'electionDayVoting');
        break;
      case 'municipality':
        MapActions.onMunicipalityOrCountryChosen(clickedItem);
        break;
      case 'country':
        MapActions.onMunicipalityOrCountryChosen(clickedItem);
        break;
      default:
        console.log('Couldn\'t recognise item type')
    }
  }

  hideSearchResultsDropDown() {
    SearchActions.hideSearchResultDropdown()
  }

  onSearchButtonClick(e) {
    e.preventDefault();
    if (this.searchInput.value) SearchActions.makeSearchRequest(this.searchInput.value.replace(/\s+/g, ' ').trim())
  }

  render() {
    const context = (this.props.map.pageContext === 'advanceVoting') ? TranslateStore.translate('advance_voting') : TranslateStore.translate('election_day_voting');
    const inputDescription = TranslateStore.translate('search.context') + context + '. ' + TranslateStore.translate('search.address_input_instructions')

    return (
      <div id="search-panel" role="search">
        <div id="voting-type-tabs">
          <T el="span" k="advance_voting"
            id="advancevotingtab"
            className={this.props.map.pageContext != 'advanceVoting' ? 'inactive' : ''}
            onClick={() => { !isAdvancePollingEnded() ? MapActions.changeContext('advanceVoting') : undefined }}
            onKeyUp={(event) => event.key === 'Enter' ? (!isAdvancePollingEnded() ? MapActions.changeContext('advanceVoting') : undefined) : undefined}
            tabIndex="0" />
          <T el="span" k="election_day_voting"
            className={this.props.map.pageContext != 'electionDayVoting' ? 'inactive' : ''}
            onClick={() => { MapActions.changeContext('electionDayVoting') }}
            onKeyUp={(event) => event.key === 'Enter' ? MapActions.changeContext('electionDayVoting') : undefined}
            tabIndex="0" />
        </div>
        <table id="search-container" role="presentation">
          <tbody style={{ display: 'table-row-group' }}>
            <tr>
              <td><img style={{ height: '50px' }} alt="Väestörekisterikeskus" src={vrkLogo} /></td>
              <td>
                <form className="inline-block" onSubmit={this.onSearchButtonClick.bind(this)}>
                  <input id="search" ref={(input) => { this.searchInput = input; }} aria-description={inputDescription}
                    aria-label={TranslateStore.translate('search.address_input')} className="search-input" placeholder={this.state.searchPlaceHolder} onChange={this.searchInputOnChange.bind(this)}
                    autoComplete = "off" aria-owns="address-results" aria-autocomplete="list" />
                </form>
              </td>
              <td style={{ width: '51px' }}>
                <div className={'search-actions search-icon cursor-pointer ' + (this.state.query ? 'search-ready' : '')} onClick={this.onSearchButtonClick.bind(this)}>
                  {
                    (this.props.search.fetchingSearchResults ? <i className={'fa fa-circle-o-notch fa-lg fa-fw fa-spin'}></i> : <i className="fa fa-search fa-lg"></i>)
                  }

                </div>
              </td>
            </tr>
          </tbody>
        </table>
        {
          this.props.search.openResultDropDown ?
            <OutsideClickableSearchResults hide={() => this.hideSearchResultsDropDown()}
              results={this.props.search.searchResults}
              onResultFocus={this.blurSearch.bind(this)}
              onSearchResultClicked={this.onSearchResultClicked.bind(this)}
            />
            : null
        }
      </div>
    );
  }
}

Search.propTypes = {
  map: PropTypes.shape({
    pageContext: PropTypes.oneOf(['electionDayVoting', 'advanceVoting'])
  }),
  search: PropTypes.shape({
    fetchingSearchResults: PropTypes.bool,
    openResultDropDown: PropTypes.bool,
    searchResults: PropTypes.shape({
      crs: PropTypes.object,
      features: PropTypes.array,
      type: PropTypes.oneOf(['FeatureCollection'])
    }) //geoJSON shape
  })
}

Search.defaultProps = {
};

export default Search;
