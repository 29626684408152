export default {
  election_day_voting: 'Election Day voting',
  advance_voting: 'Advance voting',
  info: {
    title: 'County and municipal elections 2025: Polling stations',
    election_day_voting_title: 'Polling stations on election day 13 April 2025',
    advance_voting_title: 'Advance polling stations',
    description: 'Information about the advance voting and election day voting and instructions for using the application',
    election_day_voting_info: {
      1: `
          <p>
              On election day, you can only vote at the polling station where you have been registered. 
              This is based on your permanent address on <b>21 February 2025</b>. 
              Every voter’s polling station on election is mentioned on the notification of the right to vote that has been sent to all those entitled to vote.
          </p>

          <p>
              You can see your polling station on election day on the map by entering the permanent address which you had on <b>21 February 2025</b> in the search field. 
              Then select the correct address from the appearing suggestions. 
          </p>
        `,
      2: `
          <p>
              On election day, you can only vote at the polling station where you have been registered. 
              This is based on your permanent address on <b>21 February 2025</b>. 
              Every voter’s polling station on election is mentioned on the notification of the right to vote that has been sent to all those entitled to vote.
          </p>

          <p>
              You can see your polling station on election day on the map by entering the permanent address which you had on <b>21 February 2025</b> in the search field. 
              Then select the correct address from the appearing suggestions. 
          </p>
        `
    },
    advance_voting_info: {
      1: `
          <p>
              Advance voting will take place from <b>2 to 8 April 2025</b> in Finland, and from <b>2 to 5 April 2025</b> abroad. 
              You can vote in advance at any general advance polling station.
          </p>
          <p>
              You can search for advance polling stations on the map by:
              <ul aria-label="Searching for advance polling stations">
                  <li>zooming in or out or moving the map</li>
                  <li>typing your street address or the name of your municipality in the search field</li>
                  <li>typing the name of your country in the search field (advance polling stations abroad)</li>
                  <li>using the location finder </li>
              </ul>
          </p>
        `,
      2: `
          <p>
              Advance voting will take place from <b>2 to 8 April 2025</b> in Finland, and from <b>2 to 5 April 2025</b> abroad. 
              You can vote in advance at any general advance polling station.
          </p>
          <p>
              You can search for advance polling stations on the map by:
              <ul aria-label="Searching for advance polling stations">
                  <li>zooming in or out or moving the map</li>
                  <li>typing your street address or the name of your municipality in the search field</li>
                  <li>typing the name of your country in the search field (advance polling stations abroad)</li>
                  <li>using the location finder </li>
              </ul>
          </p>
        `
    },
    read_more: 'More information on the elections: <a href=https://www.vaalit.fi/en>www.vaalit.fi/en</a>'
  },
  footer: {
    attributes: `
            <span>© Digital and Population Data Services Agency, CGI 2025</span> <br /> <span>
            Feedback: aanestyspaikat@dvv.fi</span> <br /> <span>
            <a href='accessibility_statement_en.html' target='_blank'>Accessibility statement</a><br />
            <a href='https://dvv.fi/tietosuojaseloste-aanestyspaikkapalvelu' target='_blank'>Privacy statement</a><br />
            Powered by <a href='https://www.cgi.fi' target='_blank'>CGI</a>
            </span>
        `
  },
  search: {
    no_result: 'No result!',
    placeholder: 'Lintuvaarantie 19, Espoo',
    address_input: 'Address input',
    address_input_instructions: `Write the address you want to know the closest polling station to. The application will suggest addresses, when you have written at least four characters.
      When autocomplete results are available use up and down arrows to review and enter to select.`,
    result_report: 'results are available. Use up and down arrows to review and enter to select.',
    no_result_report: 'No results are available. Try altering the search.',
    choose: 'You chose ',
    context: 'Tou have chosen '
  },
  station_info: {
    station_focus: 'Closest polling station is ',
    station_focus_2: 'Information about the nearest polling station will be read aloud. You can also navigate the polling station information yourself with the arrow keys.',
    open_on: 'The polling station is open on ',
    date: 'Date ',
    time: 'Time ',
    polling_station: 'Polling station '
  },
  geolocation_denied_message: 'You have not enabled location services. You can enable location services in your browser or phone settings.',
  geolocation_not_supported_message: 'Your browser does not support location retrieval.',
  geolocation_prompt_message: 'Accept your browser’s request to access your location.',
  geolocation_watch_timeout_message: 'Detecting your location is taking longer than expected. Have you accepted your browser’s request to access your location?'
}
