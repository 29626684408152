import React from 'react';
import TranslateActions from '../actions/TranslateActions';

import 'styles/langaugeOptions.css';
import { PropTypes } from 'prop-types';

export default class LanguageOptions extends React.Component {
  render() {
    return (
      <div id="localeTabs">
        {this.props.locale != 'fi' && <a className="langOption" lang='fi' tabIndex='0' onClick={() => { TranslateActions.changeLanguage('fi') }}
          onKeyUp={(event) => event.key === 'Enter' ? TranslateActions.changeLanguage('fi') : undefined}>Suomeksi</a>}
        {this.props.locale != 'sv' && <a className="langOption" lang='sv' tabIndex='0' onClick={() => { TranslateActions.changeLanguage('sv') }}
          onKeyUp={(event) => event.key === 'Enter' ? TranslateActions.changeLanguage('sv') : undefined}>På Svenska</a>}
        {this.props.locale != 'en' && <a className="langOption" lang='en' tabIndex='0' onClick={() => { TranslateActions.changeLanguage('en') }}
          onKeyUp={(event) => event.key === 'Enter' ? TranslateActions.changeLanguage('en') : undefined}>In English</a>}
      </div>
    )
  }
}

LanguageOptions.propTypes = {
  locale: PropTypes.string
}

LanguageOptions.defaultProps = {
};
