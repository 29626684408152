export default {
  election_day_voting: 'Vaalipäivän äänestys',
  advance_voting: 'Ennakkoäänestys',
  info: {
    title: 'Alue- ja kuntavaalit 2025: Äänestyspaikkapalvelu',
    election_day_voting_title: 'Vaalipäivän äänestyspaikat 13.4.2025',
    advance_voting_title: 'Ennakkoäänestyspaikat',
    description: 'Informaatiota ennakkoäänestyksestä ja vaalipäivän äänestyksestä sekä ohjeita sovelluksen käyttöön',
    election_day_voting_info: {
      1: `
          <p>
              Vaalipäivänä voit äänestää vain omassa äänestyspaikassasi, joka on määräytynyt vakinaisen osoitteesi perusteella <span aria-hidden="true"><b>21.2.2025</b></span><span class="sr-only">kahdeskymmenesensimmäinen helmikuuta 2025</span>. 
              Vaalipäivän äänestyspaikka on kerrottu kaikille äänioikeutetuille lähetetyssä äänioikeusilmoituksessa.
          </p>

          <p>
              Voit katsoa oman äänestyspaikkasi kartalta kirjoittamalla hakukenttään vakinaisen osoitteesi, joka sinulla oli <span aria-hidden="true"><b>21.2.2025</b></span><span class="sr-only">kahdeskymmenesensimmäinen helmikuuta 2025</span>. 
              Valitse sitten oikea osoite kenttään avautuvista ehdotuksista. 
          </p>
        `,
      2: `
          <p>
              Vaalipäivänä voit äänestää vain omassa äänestyspaikassasi, joka on määräytynyt vakinaisen osoitteesi perusteella <span aria-hidden="true"><b>21.2.2025</b></span><span class="sr-only">kahdeskymmenesensimmäinen helmikuuta 2025</span>. 
              Vaalipäivän äänestyspaikka on kerrottu kaikille äänioikeutetuille lähetetyssä äänioikeusilmoituksessa.
          </p>

          <p>
              Voit katsoa oman äänestyspaikkasi kartalta kirjoittamalla hakukenttään vakinaisen osoitteesi, joka sinulla oli <span aria-hidden="true"><b>21.2.2025</b></span><span class="sr-only">kahdeskymmenesensimmäinen helmikuuta 2025</span>. 
              Valitse sitten oikea osoite kenttään avautuvista ehdotuksista. 
          </p>
        `
    },
    advance_voting_info: {
      1: `
          <p>
              Ennakkoäänestys kotimaassa <span aria-hidden="true"><b>2.4.-8.4.2025</b></span>
              <span class="sr-only">toinen huhtikuuta viiva kahdeksas huhtikuuta 2025</span>
              ja ulkomailla <span aria-hidden="true"><b>2.4.-5.4.2025</b></span><span class="sr-only">toinen huhtikuuta viiva viides huhtikuuta 2025</span>.
              Voit käydä äänestämässä ennakkoon missä tahansa yleisessä ennakkoäänestyspaikassa.
          </p>
          <p>
              Voit hakea ennakkoäänestyspaikkoja kartalta:
              <ul>
                  <li>suurentamalla, pienentämällä ja liikuttamalla karttaa</li>
                  <li>kirjoittamalla hakukenttään katuosoitteen tai kunnan</li>
                  <li>kirjoittamalla hakukenttään valtion (ulkomaan ennakkoäänestyspaikat)</li>
                  <li>käyttämällä paikannustoimintoa</li>
              </ul>
          </p>
        `,
      2: `
      <p>
              Ennakkoäänestys kotimaassa <span aria-hidden="true"><b>2.4.-8.4.2025</b></span>
              <span class="sr-only">toinen huhtikuuta viiva kahdeksas huhtikuuta 2025</span>
              ja ulkomailla <span aria-hidden="true"><b>2.4.-5.4.2025</b></span><span class="sr-only">toinen huhtikuuta viiva viides huhtikuuta 2025</span>.
              Voit käydä äänestämässä ennakkoon missä tahansa yleisessä ennakkoäänestyspaikassa.
          </p>
          <p>
              Voit hakea ennakkoäänestyspaikkoja kartalta:
              <ul>
                  <li>suurentamalla, pienentämällä ja liikuttamalla karttaa</li>
                  <li>kirjoittamalla hakukenttään katuosoitteen tai kunnan</li>
                  <li>kirjoittamalla hakukenttään valtion (ulkomaan ennakkoäänestyspaikat)</li>
                  <li>käyttämällä paikannustoimintoa</li>
              </ul>
          </p>
    `
    },
    read_more: 'Lisätietoa vaaleista: <a href=https://www.vaalit.fi>www.vaalit.fi</a>'
  },
  footer: {
    attributes: `
            <span>© Digi- ja väestötietovirasto, CGI 2025</span> <br /> <span>
            Palaute: aanestyspaikat@dvv.fi</span> <br /> <span>
            <a href='accessibility_statement_fi.html' target='_blank'>Saavutettavuusseloste</a><br />
            <a href='https://dvv.fi/tietosuojaseloste-aanestyspaikkapalvelu' target='_blank'>Tietosuojaseloste</a><br />
            Powered by <a href='https://www.cgi.fi' target='_blank'>CGI</a>
            </span>
        `
  },
  search: {
    no_result: 'Ei tuloksia!',
    placeholder: 'Lintuvaarantie 19, Espoo',
    address_input: 'Osoitteen syöttö',
    address_input_instructions: `Kirjoita tähän osoite, jota lähimmän äänestyspaikan haluat hakea. Ohjelma ehdottaa sinulle osoitteita, kun olet kirjoittanut vähintään neljä merkkiä.
      Kun automaattisen täytön tulokset ovat valmiit, navigoi hakutuloksia ylä ja alanuolilla. Valitse hakutulos enteriä painamalla.`,
    result_report: ' hakutulosta valittavissa. Navigoi hakutuloksia ylä ja alanuolilla. Valitse hakutulos enteriä painamalla.',
    no_result_report: 'Ei hakutuloksia. Kokeile muokata hakua.',
    choose: 'Valitsit ',
    context: 'Valittuna on '
  },
  station_info: {
    station_focus: 'Lähin äänestyspaikka on ',
    station_focus_2: 'Seuraavaksi ruudunlukija lukee sinulle tietoja lähimmästä äänestyspaikasta. Voit myös navigoida informaatiota itse nuolinäppäimillä.',
    open_on: 'Äänestyspaikka on avoinna seuraavasti ',
    date: 'Päivämäärä ',
    time: 'Aika ',
    polling_station: 'Äänestyspaikka '
  },
  geolocation_denied_message: 'Et ole antanut lupaa paikannukseen. Voit sallia paikannuksen selaimen tai puhelimen asetuksista.',
  geolocation_not_supported_message: 'Paikannus epäonnistui tai selaimesi ei tue paikannusta.',
  geolocation_prompt_message: 'Hyväksy selaimen paikannuspyyntö.',
  geolocation_watch_timeout_message: 'Paikannus kestää odotettua pidempään. Oletko hyväksynyt selaimen paikannuspyynnön?'
}
