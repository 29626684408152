import React from 'react';
import AppOptionsStore from '../stores/AppOptionsStore';
import TranslateStore from '../stores/TranslateStore';
import { getFirstRoundEnd } from '../stores/dates';
import PropTypes from 'prop-types';
import { focusInfoPopup, notifyAboutClosestStation, titleStationIcons } from '../utils/accessibility';

export default class PollingStationInfo extends React.Component {

  /* Focus the info popup when component mounts, gives the polling station icon a title and reads instructions aloud for screen reader users.
  Improves accessibility. componentDidMount() only works for election day polling stations, because advance polling stations are rendered as
  static markup (see MarkerCluster). Opening and focusing advance polling station popups is handled in MapContainer. */
  componentDidMount() {
    TranslateStore.listen(this.update.bind(this));

    if (this.props.isClosestStation) {
      titleStationIcons();

      focusInfoPopup(this.props.info.id);

      const stationname = TranslateStore.getLocalisedValue('stationname', this.props.info);
      notifyAboutClosestStation(stationname)
    }
  }

  componentWillUnmount() {
    TranslateStore.unlisten(this.update.bind(this))
  }

  // When langauge preference change signal comes via TranslateStore update the component
  update() {
    this.forceUpdate();
  }

  renderOpenTimes() {
    // opentimes is assumed to come in array with length of 4's multiple,
    // each 4 consecutive items in the array represnt one schedule row,
    // first 2 items are fromDate and toDate, last 2 items are from Time and toTime,
    // date in the format 22.07.2017
    // time in the format 19:30
    // If there is only fromDate and no toDate, it means it is only one day(fromDate) schedule

    // Assuming data's structure as above it is parsed and presented as following
    let appOptions = AppOptionsStore.getState().appOptions
    if (!appOptions) {
      return null;
    }


    let opentimes = this.props.info.opentimes.slice();
    var firstRoundOpentimesSeparated = [];
    var secondRoundOpentimesSeparated = [];
    let hideFirst = appOptions.find((x) => x.key=='hideFirst').value=='true'
    while (opentimes.length > 0) {
      // Note: when creating a new Date object, the month values are 0-11
      const separated = opentimes.splice(0, 4);
      if (separated && separated[0] !== '') {
        const aStartDateRaw = this.splitDateString(separated[0]);
        const aStartDate = new Date(aStartDateRaw[3], aStartDateRaw[2] - 1, aStartDateRaw[1]);
        if (aStartDate <= getFirstRoundEnd()) {
          firstRoundOpentimesSeparated.push(separated)
        } else {
          secondRoundOpentimesSeparated.push(separated);
        }
      }
    }

    const sortfunc = (a, b) => {
      let aStartDateRaw = this.splitDateString(a[0]);
      let aStartTimeRaw = this.splitTimeString(a[2]);
      let bStartDateRaw = this.splitDateString(b[0]);
      let bStartTimeRaw = this.splitTimeString(b[2]);

      // Note: when creating a new Date object, the month values are 0-11
      return new Date(aStartDateRaw[3], aStartDateRaw[2] - 1, aStartDateRaw[1], aStartTimeRaw[1]) -
        new Date(bStartDateRaw[3], bStartDateRaw[2] - 1, bStartDateRaw[1], bStartTimeRaw[1]);
    }

    var firstRoundOpenTimesSorted = firstRoundOpentimesSeparated.sort( sortfunc )
    var secondRoundOpenTimesSorted = secondRoundOpentimesSeparated.sort( sortfunc )
    const createAccFunc = (roundClassName) => {
      return (accumulator, curItem) => {
      accumulator.push(
        <div className={roundClassName} key={Math.random().toString(36).substring(7)}>
          <span className="sr-only">{TranslateStore.translate('station_info.date')}</span>
          <span className="dates">{this.formatDate(curItem[0])}{this.formatDate(curItem[1]) ? ' - ' + this.formatDate(curItem[1]) : ''}</span>
          <span className="sr-only">{TranslateStore.translate('station_info.time')}</span>
          <span className="times">{this.formatTime(curItem[2])}-{this.formatTime(curItem[3])}</span>
        </div>
      )
      return accumulator;
      }
    }
    let firstRoundTitle = <div key={Math.random().toString(36).substring(7)}>
      <br/>
      <span className="first-round-text">{TranslateStore.translate('station_info.first_round_title')}</span>
    </div>
    firstRoundOpenTimesSorted = firstRoundOpenTimesSorted.reduce(createAccFunc('first-round-text'), []);
    let secondRoundTitle = <div key={Math.random().toString(36).substring(7)}>
    <br/>
    <span className={hideFirst ? 'second-round-only-text' : 'second-round-text'}>{TranslateStore.translate('station_info.second_round_title')}</span>
  </div>
    secondRoundOpenTimesSorted = secondRoundOpenTimesSorted.reduce(createAccFunc(hideFirst ? 'second-round-only-text' : 'second-round-text'), []);
  
    let accEl = []
    if (!hideFirst){
      accEl.push(firstRoundTitle)
      accEl.push(firstRoundOpenTimesSorted)
    }
    accEl.push(secondRoundTitle)
    accEl.push(secondRoundOpenTimesSorted)

    return accEl
  }



  splitDateString(string) {
    let regx = /(.{1,2})\.(.{1,2})\.(.{4})/
    return regx.exec(string)
  }

  splitTimeString(string) {
    let regx = /(.{1,2}):(.{1,2})/
    return regx.exec(string)
  }

  formatDate(string) {
    // Left-pad the day and month values
    var splittedDate = this.splitDateString(string);
    return splittedDate ? ('00' + splittedDate[1]).slice(-2) + '.' + ('00' + splittedDate[2]).slice(-2) + '.' : null
  }

  formatTime(string) {
    // Left-pad the time values
    var splittedTime = this.splitTimeString(string);
    return splittedTime ? ('00' + splittedTime[1]).slice(-2) + ':' + ('00' + splittedTime[2]).slice(-2) : null;
  }

  render() {
    let info = this.props.info;
    return (
      <div key={info.id} id={info.id} className="polling-station-info">
        <div className="margin-bottom-10">
          <b>{TranslateStore.getLocalisedValue('stationname', info)}</b><br />
          {info.districtcode && <span>({TranslateStore.getLocalisedValue('districtname', info)} {info.districtcode})<br /></span>}
          <em>{TranslateStore.getLocalisedValue('stationaddress', info)}</em><br />
          <em>{TranslateStore.getLocalisedValue('city', info)} {TranslateStore.getLocalisedValue('countryname', info)}</em>
        </div>
        <div className="sr-only">{TranslateStore.translate('station_info.open_on')}</div>
        {(info.opentimes && info.opentimes.length > 0) &&
        this.renderOpenTimes()}
      </div>
    )
  }
}

PollingStationInfo.propTypes = {
  isClosestStation: PropTypes.bool,
  info: PropTypes.shape({
    id: PropTypes.string,
    cityfi: PropTypes.string,
    citysv: PropTypes.string,
    countrynamefi: PropTypes.string,
    countrynamesv: PropTypes.string,
    opentimes: PropTypes.array,
    postcode: PropTypes.string,
    stationaddressfi: PropTypes.string,
    stationaddresssv: PropTypes.string,
    stationnamefi: PropTypes.string,
    stationnamesv: PropTypes.string,
    stationtypecode: PropTypes.string,
    districtcode: PropTypes.string
  })
};

PollingStationInfo.defaultProps = {
};
