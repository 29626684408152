import alt from '../alt';
import MapActions from '../actions/MapActions';
import { getElectionDays, isAdvancePollingEnded } from './dates';
class MapStore {
  constructor() {
    this.bindListeners({
      showCurrentLocation: MapActions.showMarker,
      showPollingStation: MapActions.showPollingStation,
      fetchPollingStation: MapActions.fetchPollingStation,
      hidePollingStation: MapActions.hidePollingStation,
      fitMapToBoundingBox: MapActions.fitMapToBoundingBox,
      removeBoundingBox: MapActions.removeBoundingBox,
      changeContext: MapActions.changeContext,
      fetchAdvancePollingStation: MapActions.fetchAdvancePollingStation,
      setClosestAdvancePollingStation: MapActions.setClosestAdvancePollingStation,
      stopSettingClosestAdvancePollingStation: MapActions.stopSettingClosestAdvancePollingStation
    })

    this.state = {
      center: [66, 25],
      zoom: 5,
      boundingBox: null,
      currentLocationCoords: null,
      currentLocationInfo: null,
      pollingStationCoords: null,
      pollingStationInfo: null,
      closestAdvancePollingStationInfo: {id: -1},
      closestStationHasChanged: false,
      pageContext: isAdvancePollingEnded() ? 'electionDayVoting' : 'advanceVoting'
    }
  }


  changeContext( newContext ) {
      this.setState({boundingBox: null, pageContext: newContext})
  }

  fitMapToBoundingBox(bounds) {
      this.setState({boundingBox: bounds})
  }

  removeBoundingBox(){ this.setState({boundingBox: null}) }

  showCurrentLocation(data) {
      this.setState({ boundingBox: null, currentLocationCoords: data.currentLocationCoords, currentLocationInfo: data.currentLocationInfo, center: data.currentLocationCoords })
  }

  showPollingStation(data) {
      data.pollingStationProps.opentimes = getElectionDays().flat(1);
      this.setState({ boundingBox: data.bounds, pollingStationCoords: data.pollingStationCoords, pollingStationInfo: data.pollingStationProps })
  }

  fetchPollingStation() {
      this.setState({ boundingBox: null, pollingStationCoords: null, pollingStationInfo: null })
  }

  fetchAdvancePollingStation() {
      this.setState({ boundingBox: null, pollingStationCoords: null, pollingStationInfo: null })
  }

  setClosestAdvancePollingStation(data) {
    this.setState({ closestAdvancePollingStationInfo: data.pollingStationProps, closestStationHasChanged: true})
  }

  stopSettingClosestAdvancePollingStation() {
    this.setState({ closestStationHasChanged: false})
  }

  hidePollingStation() {
      this.setState({ boundingBox: null, pollingStationCoords: null, pollingStationInfo: null })
  }
}

export default alt.createStore(MapStore, 'MapStore');
