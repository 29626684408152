import React from 'react';
import PropTypes from 'prop-types';
import TranslateStore from '../stores/TranslateStore';
import MapStore from '../stores/MapStore';
import AltContainer from 'alt-container';
import LanguageOptions from './LanguageOptions';
import T from './Translate';

import { getVotingRound } from '../stores/dates';

import infoIcon from '../images/info-icon.png';
import closeIcon from '../images/close-icon.png';

export default class InfoPanel extends React.Component {

  constructor() {
    super();
    this.onChange = this.onChange.bind(this);
    this.panelRef = React.createRef();
  }

  componentDidMount() {
    TranslateStore.listen(this.onChange);
  }

  componentWillUnmount() {
    TranslateStore.unlisten(this.onChange);
  }

  onChange() {
    this.panelRef.current.setAttribute('aria-description', TranslateStore.translate('info.description'));
  }

  electionDayText() {
    return (
      [<T el="h2" k="info.election_day_voting_title" style={{ fontWeight: 'bold' }} key={Math.random().toString(36).substring(7)} />,
      <T el="p" k={`info.election_day_voting_info.${getVotingRound()}`} key={Math.random().toString(36).substring(7)} />]
    )
  }

  advanceVotingText() {
    return (
      [<T el="h2" k="info.advance_voting_title" style={{ fontWeight: 'bold' }} key={Math.random().toString(36).substring(7)} />,
      <T el="p" k={`info.advance_voting_info.${getVotingRound()}`} key={Math.random().toString(36).substring(7)} />]
    )
  }

  handleClickOutside() {
    this.props.setInfoOpen(false);
  }

  render() {
    return (
      <div>
        <div id="info-panel" role="complementary" aria-description={TranslateStore.translate('info.description')} tabIndex="0" className={this.props.infoClassName} ref={this.panelRef}>
          <div className="info-top">
            <AltContainer store={TranslateStore}><LanguageOptions /></AltContainer>
          </div>
          <div ref={(div) => { this.scrollDiv = div; }} id="info-scroll" className="infoScroll">
            <T el="h1" k="info.title" className="info-title" />
            {MapStore.getState().pageContext == 'advanceVoting' ? this.advanceVotingText() : this.electionDayText()}
            {MapStore.getState().pageContext == 'advanceVoting' ? this.electionDayText() : this.advanceVotingText()}
            <T el="p" k="info.read_more" className="info-read-more" />
            <p style={{ height: '30px' }}></p>
          </div>
          <div className="info-footer">
            <T el="div" k="footer.attributes" className="footer-attribs" />
          </div>
        </div>
        <span id="showinfo" className={'floating-button showinfo ' + this.props.infoClassName}>
          {
            this.props.infoOpen ?
              <img src={closeIcon} className="icon-close" onClick={() => this.props.setInfoOpen(false)} onKeyUp={(event) => event.key === 'Enter' ? this.props.setInfoOpen(false) : undefined} alt="Close info panel" tabIndex="0" /> :
              <img src={infoIcon} className="icon-info" onClick={() => this.props.setInfoOpen(true)} onKeyUp={(event) => event.key === 'Enter' ? this.props.setInfoOpen(true) : undefined} alt="Open info panel" tabIndex="0" />
          }
        </span>
      </div>

    )
  }
}

InfoPanel.propTypes = {
  infoClassName: PropTypes.string,
  infoOpen: PropTypes.bool.isRequired,
  setInfoOpen: PropTypes.func.isRequired
}

InfoPanel.defaultProps = {
};
