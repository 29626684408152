import React from 'react';
import { PropTypes } from 'prop-types';
import TranslateStore from '../stores/TranslateStore';

export default class Address extends React.Component {
  render() {
      return (
          <div>
              <b>{TranslateStore.getLocalisedValue('streetname',this.props.data)} {this.props.data.streetnumber}</b><br/>
              <span>{this.props.data.postalcode} {TranslateStore.getLocalisedValue('municipalityname',this.props.data)}</span><br/>
          </div>
      )
  }
}

Address.propTypes = {
  data: PropTypes.shape({
    postalcode: PropTypes.string,
    streetnumber: PropTypes.string
  })
};


Address.defaultProps = {
};
