// Module for handling dates on elections

const electionDaySchedule = [
  // ['2.4.2023','','09:00','20:00'] // This is the election day schedule [startDate, endDate, startTime, endTime]
  // ['28.1.2024','','09:00','20:00'], // This is the election day schedule for round 1 of the 2024 presidential election, [startDate, endDate, startTime, endTime]
  // ['11.2.2024','','09:00','20:00']  // This is the election day schedule for round 2 of the 2024 presidential election, [startDate, endDate, startTime, endTime]
  //['9.6.2024','','09:00','20:00'] // This is the election day schedule for the 2024 European elections, [startDate, endDate, startTime, endTime]
  ['13.4.2025','','09:00','20:00'] // This is the election day schedule for the 2025 parliamentary elections, [startDate, endDate, startTime, endTime]
];

const electionDayEnd = [
  // Note: when creating a new Date object, the month values are 0-11
  // new Date(2023, 3, 2, 23, 59, 59) // This is the election day

  // Use for Presidential elections
  // new Date(2024, 0, 28, 23, 59, 59),  'January 28, 2024 23:59:59'  - This is election day for round 1 of the 2018 presidential election
  // new Date(2024, 1, 11, 23, 59, 59)   'February 11, 2024 23:59:59' - This is election day for round 2 of the 2018 presidential election
  //new Date(2024, 5, 20, 23, 59, 59) // 'June 9, 2024 23:59:59' - This is the election day for the 2024 European elections
  new Date(2025, 3, 13, 23, 59, 59) // 'April 13, 2025 23:59:59' - This is the election day for the 2025 county and municipal elections
];

const advancePollingDeadline = [
  // Note: when creating a new Date object, the month values are 0-11
  // new Date(2023, 2, 28, 23, 59, 59) //

  // Use for Presidential elections
  // new Date(2024, 0, 23, 23, 59, 59), // 'January 23, 2024 23:59:59' - This deadline is for round 1 of the 2018 presidential election
  // new Date(2024, 1, 6, 23, 59, 59)   // 'February 6, 2024 23:59:59' - This deadline is for round 2 of the 2018 presidential election
  //new Date(2024, 5, 15, 23, 59, 59)   // 'June 4, 2024 23:59:59' - This deadline is for the 2024 advance European election
  new Date(2025, 3, 8, 23, 59, 59)   // 'April 8, 2025 23:59:59' - This deadline is for the 2025 advance county and municipal election
];

const serviceWillOpen = new Date(2025, 2, 16, 23, 59, 59); // The day when the service will open


const getDate = () => {
  // HERE YOU CAN TEST DATES
  // return new Date(2024, 0, 21, 12, 0, 0, 0); // Round 1 advance voting
  // return new Date(2024, 0, 24, 12, 0, 0, 0); // Round 1 voting
  // return new Date(2024, 0, 28, 12, 0, 0, 0); // Election day round 1
  // return new Date(2024, 0, 29, 12, 0, 0, 0); // Round 2 advance voting
  // return new Date(2024, 1, 7, 12, 0, 0, 0);  // Round 2 voting
  // return new Date(2024, 1, 11, 12, 0, 0, 0); // Election day round 2
  // return new Date(2024, 1, 12, 1, 0, 0, 0);

  // Return this on production!!!
  return new Date();
}

const getVotingRound = () => (getDate() > electionDayEnd[0]) ? 2 : 1;
const getFirstRoundEnd = () => electionDayEnd[0];
const getElectionDays = () => electionDaySchedule;
const getElectionDayScheduleForCurrentRound = () => electionDaySchedule[getVotingRound() - 1];
const isAdvancePollingEnded = () => advancePollingDeadline[getVotingRound() - 1] < getDate();
const isServiceOpened = () => serviceWillOpen < getDate();
const isServiceClosed = () => electionDayEnd[electionDayEnd.length - 1] < getDate();

export {
  getVotingRound,
  getFirstRoundEnd,
  getElectionDays,
  getElectionDayScheduleForCurrentRound,
  isAdvancePollingEnded,
  isServiceOpened,
  isServiceClosed
}