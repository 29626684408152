import React from 'react';
import PropTypes from 'prop-types';
import TranslateStore from '../stores/TranslateStore';

export default class Translate extends React.Component {
  /*
    Usage Examples:
    import T from './Translate';

    // k is key used for finding right value from language file.
    // This creates span to wrap around the translated text

    <T k="info.title" />


    // if instead you want specific element to be created pass the tag name in props named el
    // The usage below creates h5 element and puts the translated text as its inner html.
    // You can also pass props for the element. In the case below style is applied to h5 element.

    <T el="h5" k="info.title" style={{background: 'red'}} />

  */
  constructor() {
      super();
      this.state = TranslateStore.getState();

      this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
      TranslateStore.listen(this.onChange);
  }

  componentWillUnmount() {
      TranslateStore.unlisten(this.onChange);
  }

  onChange(state) {
      this.setState(state)
  }

  render() {
      var el;
      if (this.props.el) {
        var props = {...this.props}
        props.dangerouslySetInnerHTML = {
            __html: TranslateStore.translate(this.props.k)
        }
        delete props.el;
        el = React.createElement(
            this.props.el,
            props
        )
      } else {
          el = (<span>{TranslateStore.translate(this.props.k)}</span>)
      }
      return el
  }
}

Translate.propTypes = {
  el: PropTypes.string
}



Translate.propTypes = {
    k: PropTypes.string
};
