import React from 'react'
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom/server';
import L from 'leaflet';
import { MapLayer } from 'react-leaflet';
import PollingStationInfo from './PollingStationInfo';
import TranslateStore from '../stores/TranslateStore';

require('proj4leaflet');
require('../../node_modules/leaflet.markercluster/dist/leaflet.markercluster.js');
require('../../node_modules/leaflet.markercluster/dist/MarkerCluster.css');
require('../../node_modules/leaflet.markercluster/dist/MarkerCluster.Default.css');

export default class MarkerCluster extends MapLayer {

  constructor() {
    super();
    this.update = this.update.bind(this);
    this.stationIcon = '<i class="fa fa-bank fa-2x station-icon"></i>';
    this.busIcon = '<i class="fa fa-bus fa-2x bus-icon"></i>';
    this.anchorIcon = '<i class="fa fa-anchor fa-2x anchor-icon"></i>';
  }

  // called by componentDidMount of MapLayer
  createLeafletElement() {
    var leafletElement = new L.markerClusterGroup({
      chunkedLoading: true,
      showCoverageOnHover: false,
      maxClusterRadius: 35
    });
    this.geoJSONLayer = new L.Proj.GeoJSON(this.props.data, {
      pointToLayer: (feature, latlng) => {

        // Determine the icon using the station type code
        const iconHtml = (code) => ({
          '8': this.busIcon,
          '9': this.anchorIcon,
          'default': this.stationIcon
        })[code];

        var icon = L.divIcon({
          className: 'polling-stations-icon',
          html: iconHtml(feature.properties.stationtypecode) || iconHtml('default'),
          iconSize: [27, 24],
          popupAnchor: [0, -10]
        });

        // keyboard: false makes markers not focusable by keyboard
        return L.marker(latlng, { icon: icon, keyboard: false});
      },
      onEachFeature: (feature, layer) => {
        let markerPopup = ReactDOM.renderToStaticMarkup(
          <PollingStationInfo info={feature.properties} />
        );
        layer.bindPopup(markerPopup, { autoPanPaddingTopLeft: [10, 100] });
      }
    });
    return leafletElement;
  }

  componentDidMount() {
    super.componentDidMount();
    TranslateStore.listen(this.update)
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    TranslateStore.unlisten(this.update)
  }

  // When langauge preference change signal comes via TranslateStore update the markerCluster
  update() {
    setTimeout(() => {
      this.leafletElement.removeLayer(this.geoJSONLayer);
      this.geoJSONLayer.clearLayers();
      this.geoJSONLayer.addData(this.props.data);
      this.leafletElement.addLayer(this.geoJSONLayer);
    })
  }

  /*
    Should not update component if data is already there.
    It always gets same data in case of VRK application
    that is why do not update once data is there
  */
  shouldComponentUpdate() {
    return !this.geoJSONLayer.getLayers().length > 0
  }

  componentDidUpdate() {
    if (this.props.data) {
      this.geoJSONLayer.addData(this.props.data);
      this.leafletElement.addLayer(this.geoJSONLayer)
    }
  }

  render() {
    return null;
  }
}

MarkerCluster.defaultProps = {
};

MarkerCluster.propTypes = {
  data: PropTypes.shape({
    crs: PropTypes.object,
    features: PropTypes.array,
    type: PropTypes.oneOf(['FeatureCollection'])
  }) //geoJSON shape
};
