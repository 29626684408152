import React from 'react';
import PropTypes from 'prop-types';
import T from './Translate';
import TranslateStore from '../stores/TranslateStore';
import { srSpeak } from '../utils/accessibility';

class SearchResults extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      chosenItem: 0
    };
    /* https://gist.github.com/Restuta/e400a555ba24daa396cc
     keydown eventlistner(see componentDidMount) would not get removed on
    component unmount unless the reference was
     kept like this. */
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown, true);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown, true);
  }

  announceResults() {
    let number = this.props.results.features.length;
    let textToRead = number + TranslateStore.translate('search.result_report');

    // if results length === 0 then say "no search results"
    if (number === 0) {
      textToRead = TranslateStore.translate('search.no_result_report');
    }
    srSpeak(textToRead);
  }

  /*
  
  */
  handleKeyDown(e) {
    if (e.keyCode == 38) {
      // scroll up
      if (this.state.chosenItem > 1) {
        // onResultFocus is called so that parent Search can unfocus its search input field
        // because if search input is focused while we also have selected search result item
        // on enter pressed search will be submitted and item selection too is triggered
        this.props.onResultFocus();
        this.setState({ chosenItem: this.state.chosenItem - 1 })

        const resultsList = document.getElementById('address-results');
        const text = resultsList.children[this.state.chosenItem - 1].children[0].text;
        srSpeak(text);
      } else {
        const search = document.getElementById('search');
        const end = search.value.length;
        search.setSelectionRange(end, end);
        search.focus();
        this.setState({ chosenItem: this.state.chosenItem - 1 })
      }
    } else if (e.keyCode == 40) {
      // scroll down
      if (this.props.results.features.length >= this.state.chosenItem + 1) {
        this.props.onResultFocus();
        this.setState({ chosenItem: this.state.chosenItem + 1 });

        // Screen reader reads list items aloud
        const resultsList = document.getElementById('address-results');
        const text = resultsList.children[this.state.chosenItem - 1].children[0].text;
        srSpeak(text);
      }
    } else if (e.keyCode == 13) {
      // enter
      if (this.state.chosenItem != 0) {
        this.onResultClicked(this.props.results.features[this.state.chosenItem - 1])
      }
    }
  }

  handleClickOutside() {
    this.props.hide();
  }

  onResultClicked(properties) {
    // Screen-reader reads selected address aloud
    srSpeak(TranslateStore.translate('search.choose') + this.getFullAddress(properties.properties, properties.properties.resulttype));
    
    this.props.onSearchResultClicked(properties);
  }

  getFullAddress(properties, type) {
    switch (type) {
      case 'address':
        return TranslateStore.getLocalisedValue('streetname', properties) + ' ' + (properties.streetnumber ? (' ' + properties.streetnumber) : null) + ', ' + TranslateStore.getLocalisedValue('municipalityname', properties);
      case 'municipality': //fall-through
      case 'country':
        return TranslateStore.getLocalisedValue('name', properties);
      default:
        return '';
    }
  }

  renderNoResult() {
    this.announceResults();
    return (<li className="cursor-pointer"><T el="a" k="search.no_result" /></li>)
  }

  renderAdrress(result, isChosen) {
    return (
      <li className="cursor-pointer" key={result.properties.id + result.properties.resulttype} onClick={this.onResultClicked.bind(this, result)}>
        <a className={isChosen ? 'focus' : ''}>{this.getFullAddress(result.properties, 'address')}</a>
      </li>
    )
  }

  renderMunicipality(result, isChosen) {
    return (
      <li className="cursor-pointer" key={result.properties.muncode + result.properties.resulttype} onClick={this.onResultClicked.bind(this, result)}>
        <a className={isChosen ? 'focus' : ''}>{this.getFullAddress(result.properties, 'municipality')}</a>
      </li>
    )
  }

  renderCountry(result, isChosen) {
    return (
      <li className="cursor-pointer" key={result.properties.id + result.properties.resulttype} onClick={this.onResultClicked.bind(this, result)}>
        <a className={isChosen ? 'focus' : ''}>{this.getFullAddress(result.properties, 'country')}</a>
      </li>
    )
  }

  renderResultsList(features) {
    this.announceResults();
    return features.map((result, i) => {
      var resulttype = result.properties.resulttype
      var isChosen = i + 1 == this.state.chosenItem
      return resulttype == 'address' ? this.renderAdrress(result, isChosen) :
        (resulttype == 'municipality' ? this.renderMunicipality(result, isChosen) : this.renderCountry(result, isChosen))
    })
  }

  render() {
    let features = this.props.results.features
    return (
      <div>
        <ul aria-live="assertive" role="listbox" tabIndex="0" className="search-results" id="address-results" >
          {
            features.length ? this.renderResultsList(features) : this.renderNoResult()
          }
        </ul>
      </div>
    )
  }
}

SearchResults.propTypes = {
  hide: PropTypes.func,
  results: PropTypes.shape({
    crs: PropTypes.object,
    features: PropTypes.array,
    type: PropTypes.oneOf(['FeatureCollection'])
  }), //geoJSON shape
  onResultFocus: PropTypes.func,
  onSearchResultClicked: PropTypes.func
}

export default SearchResults;
