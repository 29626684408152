import alt from '../alt';
import APollingStationsActions from '../actions/APollingStationsActions';

class APollingStationsStore {
  constructor() {
    this.bindListeners({
        fetchApollingStations: APollingStationsActions.fetchStations,
        updateApollingStations: APollingStationsActions.updateStations
    })
    this.state = {
      advancedPollingStations: null
    }
  }

  fetchApollingStations() {
    this.setState({
      advancedPollingStations: null
    })
  }

  updateApollingStations(data) {
    this.setState({
      advancedPollingStations: data
    })
  }
}

export default alt.createStore(APollingStationsStore, 'APollingStationsStore');
